export const debates = [
    {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "post_title": "On April 23rd, the Supreme Court made a decision to repeal Mike v. Gerald. What are the pros and cons of this decision?",
        "author": "Xing Madi",
        "parent": null,
        "username": "Mike Mike"
    },
    {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "post_title": "The new discussion for raising taxes is a terrible idea. What are your thoughts?",
        "author": "Xing Madi",
        "parent": null,
        "username": "Mike Mike"
    },
    {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "post_title": "The new discussion for raising taxes is a terrible idea. What are your thoughts?",
        "author": "Xing Madi",
        "parent": null,
        "username": "Mike Mike"
    },
    {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "post_title": "On April 23rd, the Supreme Court made a decision to repeal Mike v. Gerald. What are the pros and cons of this decision?",
        "author": "Xing Madi",
        "parent": null,
        "username": "Mike Mike"
    },
    {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "post_title": "On April 23rd, the Supreme Court made a decision to repeal Mike v. Gerald. What are the pros and cons of this decision?",
        "author": "Xing Madi",
        "parent": null,
        "username": "Mike Mike"
    },
]