import React from 'react'

function DashboardCategory({handleDisplay, whatToDisplay}) {

    const handleClick = (e) => {
        const show = e.target.id
        handleDisplay(show)
    }

  return (
    <div className={`bg-[#101010] text-white flex mt-7 rounded-t-2xl mx-3 sm:mx-0`}>
      <div className={`w-[33%] flex justify-center
       ${whatToDisplay === "policy-cards" ? "bg-[#101010]" : "bg-[#272727]" }
        `}>
        <button
        id="policy-cards"
        onClick={handleClick}
        className='sm:text-xl w-full py-5'>Policy Cards</button>
      </div>
      <div className={`w-[33%] flex justify-center
        ${whatToDisplay === "debate-room" ?  "bg-[#101010]" : "bg-[#272727]" }
        prounded-tr-2xl`}>
      <button 
      id="debate-room"
      onClick={handleClick}
      className='sm:text-xl w-full py-5'>Debate Room</button>
      </div>

      <div className={`w-[33%] flex justify-center
        ${whatToDisplay === "create" ?  "bg-[#101010]" : "bg-[#272727]" }
        prounded-tr-2xl`}>
      <button 
      id="create"
      onClick={handleClick}
      className='sm:text-xl w-full py-5'>Create</button>
      </div>
    </div>
  )
}

export default DashboardCategory
