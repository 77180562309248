import React from 'react'
import { debates } from '../../mockups/Dashboard/debates'
import { readableDate } from '../common/set_date'
import DiscussionActivity from './DiscussionActivity'
import DashTrendingDebates from '../DebateRoom/DashTrendingDebates'
import Debaters from '../DebateRoom/Debaters'
import img1 from "../../assets/img/avatar.png"



const DebateRoom = () => {
  let height = "h-[60vh]"
  if(debates.length < 4){
      height = "h-[50vh] sm:h-[55vh]"
  }

  return (
    <div className={`bg-[#202020] ${height} relative  text-white overflow-y-scroll
    scroll-bar-in-dark  w-full lg:w-2/3  rounded-lg`}>
        <div>
            <h1 className='font-bold text-white text-base md:text-xl lg:text-3xl p-5'>
              Welcome to the Debate Room!
            </h1>
        </div>

        <div className='ml-5'>
        
        {/* {debates.map(({post_title, author, username, text, date_created,
                         upvote_count, downvote_count},idx) => {
            
            const dateObject = readableDate(date_created)

            return (<div key={idx} className='px-4 sm:px-5'>
                <DiscussionActivity
                topic={post_title} 
                author={author}
                username={username} 
                date={dateObject}
                text={text}
                upvote_count={upvote_count}
                downvote_count={downvote_count}
         />
            </div>)
        })} */}
        <div className='bg-[#202020] flex flex-col sm:justify-center py-5 px-2.5 sm:px-4'>

                <div className='flex justify-around flex-col sm:px-4 mt-5'>
                <h3 className='text-base md:text-2xl font-bold'>Should there be a fixed tax on soda?</h3>
                  <p className='italic font-light my-1 text-sm md:text-xl'><i>Created by:</i> PolicyWeb</p>
                  <p className='mt-4 text-sm md:text-xl'><i>Started:</i> 12:00:00PM CST
                  <br />
                  Remaining time: 49:32:23</p>
                </div>

                <div className='flex justify-between sm:justify-center my-5'>
                <Debaters
                name="James McFly" 
                profileID={14}
                userImg={img1}
                stance="Yes, the act of..."
                 />

                <div className='my-12 sm:my-2 mr-5 sm:mr-10'>
                <h3 className='text-[18px] sm:text-2xl font-bold'>Vs</h3>
                </div>
                
                <Debaters
                name="Martin Kellogg"
                profileID={15}
                userImg={img1}
                stance="No, in the case..."
                 />
                </div>
         
          </div>
    </div>
    </div>
  )
}

export default DebateRoom