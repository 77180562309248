import React, { useState } from 'react'
import { PasswordResetInput } from '../common/input'
import { FaPlus } from 'react-icons/fa6';
import { ButtonChoose } from '../common/iconButton';

function CreateForm({category}) {

    const [videoYes, setVideoYes] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        
        if(category === "debate"){
            // perform debate dispatch
        }else{
            // perform discussion dispatch
        }
    }

    return (
        <div className='p-3 my-5'>
            <form className='py-5' onSubmit={handleSubmit}>
                <div className='space-y-3 sm:px-[70px]'>
                    <PasswordResetInput
                        handleChange={() => {

                        }}
                        value=""
                        name="title"
                        label="Title:"
                    />

                    <PasswordResetInput
                        handleChange={() => {

                        }}
                        value=""
                        name="topic"
                        label="Question/Topic:"
                    />
                </div>

                <div className='my-3 sm:px-[70px]'>
                    <p>Brief Description:</p>
                    <textarea
                        value=""
                        onChange={(e) => {

                        }}
                        className='text-black p-4 my-2.5 w-full lg:min-w-[420px]
                             min-h-[140px] rounded-xl'>
                    </textarea>
                </div>

                <div className='my-5 sm:px-[70px]'>
                    <p>Allow Videos?</p>
                    <ButtonChoose
                        onYes={() => {
                            setVideoYes(true)
                        }}
                        onNo={() => {
                            setVideoYes(false)
                        }}
                    />
                </div>

                <div className='my-5 sm:px-[70px]'>
                    <p>{videoYes ? "Add Photos/Videos" : "Add Photos"}</p>
                    <label
                        htmlFor='mediaUpload'
                        className='cursor-pointer w-[120px] my-3 rounded-xl h-[120px] 
                        flex justify-center items-center bg-nightModeDark_200'>
                        <FaPlus className='text-5xl' />
                    </label>
                    <input
                        id="mediaUpload"
                        className='hidden'
                        type='file'
                        accept={videoYes ? "video/*, image/* " : "image/*"}
                        onChange={() => {

                        }} />
                </div>

                {category === "debate" && 
                (<>
                    <div className='my-5 sm:px-[70px]'>
                    <p>Are you one of the debators?</p>
                    <ButtonChoose
                        onYes={() => {
                            setVideoYes(true)
                        }}
                        onNo={() => {
                            setVideoYes(false)
                        }}
                    />
                </div>
                </>)}


            <div className='space-y-4 sm:space-y-0 sm:space-x-4 mt-10 w-full sm:flex sm:justify-end'>
            <button className='bg-nightModeDark_200 py-3 rounded-lg w-full sm:w-1/4'>Save as Draft</button>
            <button className='bg-enhancedTeal py-3 rounded-lg w-full sm:w-1/4'>Post</button>
            </div>

            </form>
        </div>
    )
}

export default CreateForm