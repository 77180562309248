import React, { useEffect, useState } from 'react'
import { FiSettings } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { authTokens, userInfo, userUpdatedState } from '../../redux/Selectors/selectors'
import UserAvatar from './UserAvatar'
import { uploadImageToCloudinary } from '../common/uploadProfileImage'
import { getUserAPI, updateImage } from '../../store/auth/thunk'




function UserProfile() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(userInfo)
  const userUpdate = useSelector(userUpdatedState)
  const tokens = useSelector(authTokens)
  const location = useLocation()

  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUDNAME
  const uploadPresetName = process.env.REACT_APP_CLOUDINARY_PRESET
  const [imageUploaded, setImageUploaded] = useState(null) //Image user uploaded
  const [initialImage, setInitialImage] = useState(null) //Image to display

  const [isLocationSettings] = useState(location && location.pathname === "/settings")
  const [avatarListShown, setAvatarListShown] = useState(false)

  const editAvatarClick = () => {
    setAvatarListShown(!avatarListShown)
  }


  // IMAGE ONCHAGE
  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    if (file) {
      setImageUploaded(file)
      const reader = new FileReader();
      reader.onloadend = () => {
        setInitialImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }


  const newAvatarPicked = async () => {
    const imgUrl = await uploadImageToCloudinary(imageUploaded, cloudName, uploadPresetName)

    // Upload Image to server
    dispatch(
      updateImage({
        token: tokens.accessToken,
        user_id: user && user.id,
        new_image_url: imgUrl
      })
    )
    navigate("/profile")
  }


  useEffect(() => {
    dispatch(
      getUserAPI()
    )

  // eslint-disable-next-line 
  }, [isLocationSettings, userUpdate])

  return (
    <div>
      <div className='flex justify-between relative'>
        <div className='relative flex
              w-full lg:w-2/4'>
          <UserAvatar
            settingsPage={isLocationSettings}
            handleClick={editAvatarClick}
          />

          {user && <div className='relative md:ml-[150px] p-5 w-full sm:w-[75%] '>
            <div className='flex flex-col md:flex-row justify-between m-3'>
              <p className='text-xl font-bold'>{user?.name}</p>
              {user?.city && (<p className='text-sm'>{user.city}, {user.state}</p>)}
            </div>

            <p className='m-3'>{user?.bio}</p>
          </div>}

          {/* Close the avatar list when user leaves settings page */}
          {(isLocationSettings && avatarListShown)
          &&
            <div>
              {/* If user clicks the avatar on settings page, show avatar list */}
              <div className='flex items-center justify-center 
                            absolute z-[5] top-0 left-[105px] md:left-[155px]
                            px-4 py-2 h-[200px] md:h-[120px] w-[190px] md:w-[250px] 
                            rounded-2xl bg-updatedPurple '>
                <div
                  className="md:space-x-3 flex flex-col md:flex-row justify-center"
                >

                  <label
                    htmlFor="file-upload"
                    className="cursor-pointer w-full"
                  >

                    <div className="h-[100px] w-[100px] rounded-full  border-2 border-white
             border-spacing-3 text-center border-dashed">
                      {initialImage ?
                        <div className='h-[100px] w-[100px]'>
                          <img
                            src={initialImage}
                            className="w-full h-full rounded-full"
                            alt="Uploaded preview" />
                        </div>
                        :
                        <p className="font-thin text-sm p-3">
                          Click to browse an image.</p>
                      }
                    </div>
                  </label>
                  <input
                    id="file-upload"
                    className="hidden"
                    type="file"
                    accept=".png, .jpg, .jpeg, image/*"
                    onChange={handleImageUpload}
                  />

                  <div className='flex items-center justify-center md:px-4 mt-8 md:m-0'>
                    <button
                      onClick={newAvatarPicked}
                      className="text-white bg-enhancedTeal hover:bg-[#284B63] focus:ring-4
                   focus:outline-none focus:ring-blue-300 font-medium
                   rounded-lg text-md w-full sm:w-auto px-5 py-2.5 text-center "
                    >
                      Submit
                    </button>
                  </div>
                </div>

              </div>
            </div>

          }

        </div>

        {!isLocationSettings &&
          <Link
            to="/settings"
            className='absolute -top-3 right-2 lg:relative'
          >
            <FiSettings
              className='text-3xl lg:text-4xl' />
          </Link>

        }


      </div>
    </div>
  )
}

export default UserProfile
