import React from 'react'
import Thread from '../../components/Dashboard/DashCRUD/Thread';


const Comments = () => {
  return (
    <div className='w-full md:w-3/4'>
    <div className='flex flex-col p-3 ml-4'>
    <div>
       <div className='flex flex-row justify-between mr-4'>

       <div className=' flex flex-row space-x-4'>
        <div >
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="25" fill="#3C6E71"/>
        </svg>
        </div>

        <div className='flex flex-col'>
        <h1 className='font-medium font-nunito text-sm md:text-xl'>Name of User</h1>
        <h2 className='font-medium font-nunito text-sm md:text-xl'>Title</h2>
        </div>
        <h1 className='font-medium font-nunito text-sm md:text-xl'>Date</h1>
       </div>

        <div>
        <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.32323 8.8858C5.2256 8.72104 5.2256 8.45393 5.32323 8.28917L7.55806 4.51789C7.80214 4.10601 8.19787 4.10601 8.44195 4.5179L10.6768 8.28917C10.7744 8.45392 10.7744 8.72104 10.6768 8.88579C10.5791 9.05054 10.4209 9.05054 10.3232 8.88579L8.25001 5.38724L8.25001 17.719C8.25001 17.952 8.13808 18.1409 8.00001 18.1409C7.86194 18.1409 7.75001 17.952 7.75001 17.719L7.75001 5.38723L5.67678 8.8858C5.57915 9.05055 5.42086 9.05055 5.32323 8.8858ZM3 18.5628C3 18.3298 2.88807 18.1409 2.75 18.1409C2.61193 18.1409 2.5 18.3298 2.5 18.5628V19.8284C2.5 21.4594 3.2835 22.7815 4.25 22.7815H11.75C12.7165 22.7815 13.5 21.4594 13.5 19.8284V18.5628C13.5 18.3298 13.3881 18.1409 13.25 18.1409C13.1119 18.1409 13 18.3298 13 18.5628V19.8284C13 20.9934 12.4404 21.9378 11.75 21.9378H4.25C3.55964 21.9378 3 20.9934 3 19.8284V18.5628Z" fill="white"/>
        </svg>

        </div>
       </div>
       <div className='mt-2'>
        <p className='font-nunito font-medium w-full text-sm md:text-xl'>
        Government policies regarding raising taxes are often complex and contentious, reflecting the broader economic, social, and political environment. 
        Tax policies are typically framed within the context of fiscal sustainability, economic growth, social equity, and public welfare. 
        When governments choose to raise taxes, the decision is usually driven by the need to increase revenue for public expenditure, fund essential services, reduce fiscal deficits, or manage public debt.
        </p>

        <div className='flex flex-row p-5 mt-8 space-x-4'>

         <div className='flex '>
         <p className='leading-8'>237</p>
         <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M8.74767 5.53412L8.3059 11.8125H12C12.5523 11.8125 13 12.568 13 13.5V19.4063C13 20.3382 12.5523 21.0938 12 21.0938H5.5V11.8125H6.03014L7.38088 5.34416C7.41613 5.17539 7.51063 5.0625 7.61667 5.0625L8.49942 5.0625C8.64917 5.0625 8.76533 5.28317 8.74767 5.53412ZM5.45732 10.9688H5.6762L6.90931 5.06373C7.01504 4.55742 7.29855 4.21875 7.61667 4.21875L8.49942 4.21875C8.94869 4.21875 9.29716 4.88075 9.24419 5.63361L8.86878 10.9688H12C12.8284 10.9688 13.5 12.102 13.5 13.5V19.4063C13.5 20.8042 12.8284 21.9375 12 21.9375H5.45732C5.35436 22.4291 5.07655 22.7812 4.75 22.7812H3.25C2.83579 22.7812 2.5 22.2146 2.5 21.5156V11.3906C2.5 10.6916 2.83579 10.125 3.25 10.125H4.75C5.07656 10.125 5.35437 10.4772 5.45732 10.9688ZM5 11.3906C5 11.1576 4.88807 10.9688 4.75 10.9688H3.25C3.11193 10.9688 3 11.1576 3 11.3906V21.5156C3 21.7486 3.11193 21.9375 3.25 21.9375H4.75C4.88807 21.9375 5 21.7486 5 21.5156V11.3906Z" fill="white"/>
         </svg>
         </div>

         <div className='flex '>
         <p className='leading-8'>237</p>
         <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M12.75 16.0312H11.25C11.112 16.0312 11.0001 15.8425 11 15.6096C11 15.6095 11 15.6095 11 15.6094L11 5.48438C11 5.25138 11.1119 5.0625 11.25 5.0625L12.75 5.0625C12.8881 5.0625 13 5.25138 13 5.48438L13 15.6094C13 15.8424 12.8881 16.0312 12.75 16.0312ZM10.5427 5.0625C10.6456 4.57093 10.9234 4.21875 11.25 4.21875L12.75 4.21875C13.1642 4.21875 13.5 4.78539 13.5 5.48438L13.5 15.6094C13.5 16.3084 13.1642 16.875 12.75 16.875H11.25C10.9234 16.875 10.6456 16.5228 10.5427 16.0312H10.3238L9.09069 21.9363C8.98496 22.4426 8.70145 22.7812 8.38333 22.7812H7.50058C7.05131 22.7812 6.70284 22.1192 6.75581 21.3664L7.13122 16.0312H4C3.17157 16.0312 2.5 14.898 2.5 13.5L2.5 7.59375C2.5 6.19578 3.17157 5.0625 4 5.0625L10.5427 5.0625ZM10.5 5.90625L4 5.90625C3.44772 5.90625 3 6.66177 3 7.59375L3 13.5C3 14.432 3.44772 15.1875 4 15.1875H7.6941L7.25233 21.4659C7.23467 21.7168 7.35082 21.9375 7.50058 21.9375H8.38333C8.48937 21.9375 8.58387 21.8246 8.61912 21.6558L9.96986 15.1875H10.5L10.5 5.90625Z" fill="white"/>
         </svg>

         </div>
          
          <div>
            <p className='font-nunito font-medium text-sm md:text-xl'>Reply</p>
          </div>

        </div>
        <div className='ml-5'>
         <div className='flex flex-row  items-center'>
          <p className='font-medium font-nunito text-sm md:text-xl mr-2'>Replies(32)</p>
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.86603 9.5C6.48113 10.1667 5.51887 10.1667 5.13397 9.5L0.803847 2C0.418947 1.33333 0.900073 0.5 1.66987 0.5L10.3301 0.5C11.0999 0.5 11.5811 1.33333 11.1962 2L6.86603 9.5Z" fill="white"/>
          </svg>

         </div>
        </div>

       </div>
    </div>

    <div className='mt-4'>
       <div className='flex flex-row justify-between mr-4'>

       <div className=' flex flex-row space-x-4'>
        <div >
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="25" fill="#3C6E71"/>
        </svg>
        </div>
        <div className='flex flex-col'>
        <h1 className='font-medium font-nunito text-sm md:text-xl'>Name of User</h1>
        <h2 className='font-medium font-nunito text-sm md:text-xl'>Title</h2>
        </div>
        <h1 className='font-medium font-nunito text-sm md:text-xl'>Date</h1>
       </div>

        <div>
        <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.32323 8.8858C5.2256 8.72104 5.2256 8.45393 5.32323 8.28917L7.55806 4.51789C7.80214 4.10601 8.19787 4.10601 8.44195 4.5179L10.6768 8.28917C10.7744 8.45392 10.7744 8.72104 10.6768 8.88579C10.5791 9.05054 10.4209 9.05054 10.3232 8.88579L8.25001 5.38724L8.25001 17.719C8.25001 17.952 8.13808 18.1409 8.00001 18.1409C7.86194 18.1409 7.75001 17.952 7.75001 17.719L7.75001 5.38723L5.67678 8.8858C5.57915 9.05055 5.42086 9.05055 5.32323 8.8858ZM3 18.5628C3 18.3298 2.88807 18.1409 2.75 18.1409C2.61193 18.1409 2.5 18.3298 2.5 18.5628V19.8284C2.5 21.4594 3.2835 22.7815 4.25 22.7815H11.75C12.7165 22.7815 13.5 21.4594 13.5 19.8284V18.5628C13.5 18.3298 13.3881 18.1409 13.25 18.1409C13.1119 18.1409 13 18.3298 13 18.5628V19.8284C13 20.9934 12.4404 21.9378 11.75 21.9378H4.25C3.55964 21.9378 3 20.9934 3 19.8284V18.5628Z" fill="white"/>
        </svg>

        </div>
       </div>
       <div className='mt-2'>
       <div className='m-2'>
       <svg width="220" height="131" viewBox="0 0 220 131" fill="none" xmlns="http://www.w3.org/2000/svg">
       <rect width="220" height="131" rx="5" fill="#202020"/>
       <circle cx="110" cy="66" r="20" fill="#D9D9D9"/>
       <path d="M105.794 58.5L119.294 66.2942L105.794 74.0885L105.794 58.5Z" fill="#101010"/>
       </svg>

       </div>
        <p className='font-nunito font-medium text-sm md:text-xl'>
        Government policies on raising taxes are often driven by the need to fund public services, reduce deficits, or manage debt. 
        While tax hikes can increase revenue for essential programs like healthcare, education, and infrastructure, they can also impact economic growth and affect business investments. 
        Governments must balance these considerations, ensuring taxes are fair and equitable while avoiding negative effects like reduced consumer spending or capital flight. 
        Ultimately, successful tax policies aim to support economic stability and social welfare without placing undue burdens on specific groups or harming long-term growth.
        </p>

        <div className='flex flex-row p-5 mt-8 space-x-4'>

         <div className='flex '>
         <p className='leading-8'>237</p>
         <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M8.74767 5.53412L8.3059 11.8125H12C12.5523 11.8125 13 12.568 13 13.5V19.4063C13 20.3382 12.5523 21.0938 12 21.0938H5.5V11.8125H6.03014L7.38088 5.34416C7.41613 5.17539 7.51063 5.0625 7.61667 5.0625L8.49942 5.0625C8.64917 5.0625 8.76533 5.28317 8.74767 5.53412ZM5.45732 10.9688H5.6762L6.90931 5.06373C7.01504 4.55742 7.29855 4.21875 7.61667 4.21875L8.49942 4.21875C8.94869 4.21875 9.29716 4.88075 9.24419 5.63361L8.86878 10.9688H12C12.8284 10.9688 13.5 12.102 13.5 13.5V19.4063C13.5 20.8042 12.8284 21.9375 12 21.9375H5.45732C5.35436 22.4291 5.07655 22.7812 4.75 22.7812H3.25C2.83579 22.7812 2.5 22.2146 2.5 21.5156V11.3906C2.5 10.6916 2.83579 10.125 3.25 10.125H4.75C5.07656 10.125 5.35437 10.4772 5.45732 10.9688ZM5 11.3906C5 11.1576 4.88807 10.9688 4.75 10.9688H3.25C3.11193 10.9688 3 11.1576 3 11.3906V21.5156C3 21.7486 3.11193 21.9375 3.25 21.9375H4.75C4.88807 21.9375 5 21.7486 5 21.5156V11.3906Z" fill="white"/>
         </svg>
         </div>

         <div className='flex '>
         <p className='leading-8'>237</p>
         <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M12.75 16.0312H11.25C11.112 16.0312 11.0001 15.8425 11 15.6096C11 15.6095 11 15.6095 11 15.6094L11 5.48438C11 5.25138 11.1119 5.0625 11.25 5.0625L12.75 5.0625C12.8881 5.0625 13 5.25138 13 5.48438L13 15.6094C13 15.8424 12.8881 16.0312 12.75 16.0312ZM10.5427 5.0625C10.6456 4.57093 10.9234 4.21875 11.25 4.21875L12.75 4.21875C13.1642 4.21875 13.5 4.78539 13.5 5.48438L13.5 15.6094C13.5 16.3084 13.1642 16.875 12.75 16.875H11.25C10.9234 16.875 10.6456 16.5228 10.5427 16.0312H10.3238L9.09069 21.9363C8.98496 22.4426 8.70145 22.7812 8.38333 22.7812H7.50058C7.05131 22.7812 6.70284 22.1192 6.75581 21.3664L7.13122 16.0312H4C3.17157 16.0312 2.5 14.898 2.5 13.5L2.5 7.59375C2.5 6.19578 3.17157 5.0625 4 5.0625L10.5427 5.0625ZM10.5 5.90625L4 5.90625C3.44772 5.90625 3 6.66177 3 7.59375L3 13.5C3 14.432 3.44772 15.1875 4 15.1875H7.6941L7.25233 21.4659C7.23467 21.7168 7.35082 21.9375 7.50058 21.9375H8.38333C8.48937 21.9375 8.58387 21.8246 8.61912 21.6558L9.96986 15.1875H10.5L10.5 5.90625Z" fill="white"/>
         </svg>

         </div>
          
          <div>
            <p className='font-nunito font-medium text-sm md:text-xl'>Reply</p>
          </div>

        </div>
        <div className='ml-5'>
         <div className='flex flex-row  items-center'>
          <p className='font-medium font-nunito text-sm md:text-xl mr-2'>Replies(32)</p>
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.86603 9.5C6.48113 10.1667 5.51887 10.1667 5.13397 9.5L0.803847 2C0.418947 1.33333 0.900073 0.5 1.66987 0.5L10.3301 0.5C11.0999 0.5 11.5811 1.33333 11.1962 2L6.86603 9.5Z" fill="white"/>
          </svg>

         </div>
        </div>

       </div>
    </div>
    <div className='mt-4'>
       <div className='flex flex-row justify-between mr-4'>

       <div className=' flex flex-row space-x-4'>
        <div >
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="25" fill="#3C6E71"/>
        </svg>
        </div>
        <div className='flex flex-col'>
        <h1 className='font-medium font-nunito text-sm md:text-xl'>Name of User</h1>
        <h2 className='font-medium font-nunito text-sm md:text-xl'>Title</h2>
        </div>
        <h1 className='font-medium font-nunito text-sm md:text-xl'>Date</h1>
       </div>

        <div>
        <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.32323 8.8858C5.2256 8.72104 5.2256 8.45393 5.32323 8.28917L7.55806 4.51789C7.80214 4.10601 8.19787 4.10601 8.44195 4.5179L10.6768 8.28917C10.7744 8.45392 10.7744 8.72104 10.6768 8.88579C10.5791 9.05054 10.4209 9.05054 10.3232 8.88579L8.25001 5.38724L8.25001 17.719C8.25001 17.952 8.13808 18.1409 8.00001 18.1409C7.86194 18.1409 7.75001 17.952 7.75001 17.719L7.75001 5.38723L5.67678 8.8858C5.57915 9.05055 5.42086 9.05055 5.32323 8.8858ZM3 18.5628C3 18.3298 2.88807 18.1409 2.75 18.1409C2.61193 18.1409 2.5 18.3298 2.5 18.5628V19.8284C2.5 21.4594 3.2835 22.7815 4.25 22.7815H11.75C12.7165 22.7815 13.5 21.4594 13.5 19.8284V18.5628C13.5 18.3298 13.3881 18.1409 13.25 18.1409C13.1119 18.1409 13 18.3298 13 18.5628V19.8284C13 20.9934 12.4404 21.9378 11.75 21.9378H4.25C3.55964 21.9378 3 20.9934 3 19.8284V18.5628Z" fill="white"/>
        </svg>

        </div>
       </div>
       <div className='mt-2'>
       <div className='m-2'>
       <svg width="220" height="131" viewBox="0 0 220 131" fill="none" xmlns="http://www.w3.org/2000/svg">
       <rect width="220" height="131" rx="5" fill="#202020"/>
       <circle cx="110" cy="66" r="20" fill="#D9D9D9"/>
       <path d="M105.794 58.5L119.294 66.2942L105.794 74.0885L105.794 58.5Z" fill="#101010"/>
       </svg>

       </div>
        <p className='font-nunito font-medium text-sm md:text-xl'>
        Raising taxes is a common policy tool used by governments to increase revenue for public services, reduce deficits, or address economic challenges. 
        While higher taxes can help fund essential programs and reduce income inequality, they also have the potential to impact consumer spending and business investments negatively. 
        </p>

        <div className='flex flex-row p-5 mt-8 space-x-4'>

         <div className='flex '>
         <p className='leading-8'>237</p>
         <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M8.74767 5.53412L8.3059 11.8125H12C12.5523 11.8125 13 12.568 13 13.5V19.4063C13 20.3382 12.5523 21.0938 12 21.0938H5.5V11.8125H6.03014L7.38088 5.34416C7.41613 5.17539 7.51063 5.0625 7.61667 5.0625L8.49942 5.0625C8.64917 5.0625 8.76533 5.28317 8.74767 5.53412ZM5.45732 10.9688H5.6762L6.90931 5.06373C7.01504 4.55742 7.29855 4.21875 7.61667 4.21875L8.49942 4.21875C8.94869 4.21875 9.29716 4.88075 9.24419 5.63361L8.86878 10.9688H12C12.8284 10.9688 13.5 12.102 13.5 13.5V19.4063C13.5 20.8042 12.8284 21.9375 12 21.9375H5.45732C5.35436 22.4291 5.07655 22.7812 4.75 22.7812H3.25C2.83579 22.7812 2.5 22.2146 2.5 21.5156V11.3906C2.5 10.6916 2.83579 10.125 3.25 10.125H4.75C5.07656 10.125 5.35437 10.4772 5.45732 10.9688ZM5 11.3906C5 11.1576 4.88807 10.9688 4.75 10.9688H3.25C3.11193 10.9688 3 11.1576 3 11.3906V21.5156C3 21.7486 3.11193 21.9375 3.25 21.9375H4.75C4.88807 21.9375 5 21.7486 5 21.5156V11.3906Z" fill="white"/>
         </svg>
         </div>

         <div className='flex '>
         <p className='leading-8'>237</p>
         <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M12.75 16.0312H11.25C11.112 16.0312 11.0001 15.8425 11 15.6096C11 15.6095 11 15.6095 11 15.6094L11 5.48438C11 5.25138 11.1119 5.0625 11.25 5.0625L12.75 5.0625C12.8881 5.0625 13 5.25138 13 5.48438L13 15.6094C13 15.8424 12.8881 16.0312 12.75 16.0312ZM10.5427 5.0625C10.6456 4.57093 10.9234 4.21875 11.25 4.21875L12.75 4.21875C13.1642 4.21875 13.5 4.78539 13.5 5.48438L13.5 15.6094C13.5 16.3084 13.1642 16.875 12.75 16.875H11.25C10.9234 16.875 10.6456 16.5228 10.5427 16.0312H10.3238L9.09069 21.9363C8.98496 22.4426 8.70145 22.7812 8.38333 22.7812H7.50058C7.05131 22.7812 6.70284 22.1192 6.75581 21.3664L7.13122 16.0312H4C3.17157 16.0312 2.5 14.898 2.5 13.5L2.5 7.59375C2.5 6.19578 3.17157 5.0625 4 5.0625L10.5427 5.0625ZM10.5 5.90625L4 5.90625C3.44772 5.90625 3 6.66177 3 7.59375L3 13.5C3 14.432 3.44772 15.1875 4 15.1875H7.6941L7.25233 21.4659C7.23467 21.7168 7.35082 21.9375 7.50058 21.9375H8.38333C8.48937 21.9375 8.58387 21.8246 8.61912 21.6558L9.96986 15.1875H10.5L10.5 5.90625Z" fill="white"/>
         </svg>

         </div>
          
          <div>
            <p className='font-nunito font-medium text-sm md:text-xl'>Reply</p>
          </div>

        </div>
        <div className='ml-5'>
         <div className='flex flex-row  items-center'>
          <p className='font-medium font-nunito text-sm md:text-xl mr-2'>Replies(32)</p>
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.86603 9.5C6.48113 10.1667 5.51887 10.1667 5.13397 9.5L0.803847 2C0.418947 1.33333 0.900073 0.5 1.66987 0.5L10.3301 0.5C11.0999 0.5 11.5811 1.33333 11.1962 2L6.86603 9.5Z" fill="white"/>
          </svg>

         </div>
        </div>

       </div>
    </div>

       <Thread/>
    </div>
    </div>
  )
}

export default Comments