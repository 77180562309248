import React from 'react'
import { statsByLocation } from '../../../mockups/Dashboard'
import { useSelector } from 'react-redux'
import { rankedStates } from '../../../redux/Slices/dashboardSlice'

function StatsBasics() {
  const states = useSelector(rankedStates)
  const total_users = (states && states?.total) || 0

  return (
    <div>
            <h3 className='text-xl font-semibold mt-3'>Current Users: {total_users}</h3>
      

      {total_users > 0 &&
            <div className='my-2'>
            <p>Where are most users based?</p>
              {states && states?.top_states?.map(({user_count, state}) => {
                  const calc = parseFloat(user_count / total_users) * 100
                  const position = (Math.random() * 4).toFixed(0)
                  const percentage = `${calc.toFixed(0)}%`
                  return (
                      <div key={state} className='my-2'>
                      <p>{state}</p>
                      <div 
                      style={{ backgroundColor: statsByLocation[position].color, width: percentage}}
                      className='h-3 rounded-xl text-[10px] text-end pr-2'
                      >{percentage}</div>
                      </div>
                  )
              })}
            </div>
          }

      <div className='my-5'>
        <p className='font-semibold text-xl'>Most Searched Topics</p>
        <div className='text-sm'>
            <p>Labor Laws</p>
            <p>Taxes</p>
            <p>Oil Prices</p>
        </div>
      </div>
    </div>
  )
}

export default StatsBasics
