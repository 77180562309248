import React from 'react'

// This is just a mockup 
const AiSummary = () => {
  return (
    <div className='flex flex-wrap'>
    <div className='font-bold font-nunito text-2xl'>Ai Summary</div>
     <p className='font-bold font-nunito text-base md:text-lg mt-4 '>
      The users in this thread are generally discussing the bill that was passed by congress on the 
      13th November, 2025, that would raise the taxes on processed goods. There are many users that agree 
      that this was bad move from policymakers, but there are a few who agree with the bill.
     </p>

    <div className='mt-4'>
     <h2 className='font-bold font-nunito text-base md:text-xl'>
      Against the bill(152 out of 220 replies):
     </h2>

     <p className='italic text-base  md:text-lg'>Argument 1</p>
     <p className='italic text-base  md:text-lg'>Argument 1</p>
     <p className='italic text-base  md:text-lg'>Argument 1</p>

    </div>

    <div className='mt-4'>
      <h2 className='font-bold font-nunito text-base lg:text-xl'>For the bill(68 out of 220 replies)</h2>

      <p className='italic text-base  md:text-lg'>Argument 1</p>
     <p className='italic text-base  md:text-lg'>Argument 1</p>
     <p className='italic text-base  md:text-lg'>Argument 1</p>
    </div>
    </div>
  )
}

export default AiSummary