import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
    setLoadingAndError,
    setError
} from "../../components/common/slice_state";
import { getTop5States, generalUserNeedPsychometrics } from "../Thunks/dashboardThunk";


const initialState = {
    topStates : [],
    generalPsychometrics : [],
    loading: false,
    error: null
}

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

             // POST COMMENYTS CASE
             .addCase(getTop5States.pending, setLoadingAndError)
             .addCase(getTop5States.fulfilled, (state, action) => {
                 state.loading = false;
                 state.topStates = action.payload || []
             }) 
             .addCase(getTop5States.rejected, setError)

            // POST COMMENYTS CASE
            .addCase(generalUserNeedPsychometrics.pending, setLoadingAndError)
            .addCase(generalUserNeedPsychometrics.fulfilled, (state, action) => {
                state.loading = false;
                state.generalPsychometrics = action.payload || []
            }) 
            .addCase(generalUserNeedPsychometrics.rejected, setError)
    }
})

export const rankedStates = state => state.dashboardData.topStates
export const all_userneed_psychometrics = state => state.dashboardData.generalPsychometrics

export default dashboardSlice.reducer