import React, { useEffect, useState } from 'react'
import { GoThumbsup, GoThumbsdown } from "react-icons/go"
import { Link } from 'react-router-dom'
import { readableDate } from '../common/set_date'
import { useDispatch, useSelector } from 'react-redux'
import { user_comments, userInfo } from '../../redux/Selectors/selectors'
import { fetch_comments_by_user } from '../../redux/Thunks/commentsThunk';
import Componentsloader from '../common/componentsloader';
import { selectIsAuth } from '../../store/auth/selectors'

function UserActivity({isMobileClicked, onClick}) {
    const user = useSelector(userInfo)
    const isAuth = useSelector(selectIsAuth)
    const dispatch = useDispatch()
    const loading = useSelector(state => state.all_comments.loading)
    const all_comments_by_this_user = useSelector(user_comments)
    const [userRecentActivities, setUserRecentActivities] = useState([])

    let height = "h-[70vh]"
    if(userRecentActivities.length < 5){
        height = "h-[50vh] sm:h-[35vh]"
    }

    const handleClick = (index) => {
        onClick(index)
    }
    

    function getCommentsByUser(user) {
        user && dispatch(
            fetch_comments_by_user({
                user_id: user.id,
            })
        )
    }

    useEffect(() => {

    setTimeout(() => {
        getCommentsByUser(user)
        const result = all_comments_by_this_user && all_comments_by_this_user.filter(({deleted}) => {
            return deleted === false
        })
        setUserRecentActivities(result)
    }, 1000)

    // eslint-disable-next-line 
    },[isAuth])

    return (
        <div className='relative bg-nightModeDark text-white 
        pb-3 lg:px-4 rounded-xl'>

            <p
            className={`font-semibold hidden lg:block p-4`}>
            Recent Activity</p>

            <div className='sticky lg:hidden font-semibold flex justify-between'>
            <button
            onClick={() => {
                handleClick(0)
            }}
             className={`font-semibold w-2/4 py-3 rounded-tl-md
             ${isMobileClicked && isMobileClicked[0] ? "bg-nightModeDark": "bg-greenOnDarkMode" }
             `}>
             Recent Activity</button>

             <button
            onClick={() => {
                handleClick(1)
            }}
             className={`font-semibold w-2/4 py-3 rounded-tr-md
             ${isMobileClicked && isMobileClicked[1] ? "bg-nightModeDark": "bg-greenOnDarkMode" }
             `}>
             Policy Web</button>

            </div>

            <div className={`${height} overflow-y-scroll scroll-bar-in-dark 
            text-sm py-5 px-3`}>

                {loading ?
                (
                    <Componentsloader />
                )
                :
                (
                 userRecentActivities.length > 0 ?
                    userRecentActivities?.toReversed()?.map((item, idx) => {
                    const dateObject = readableDate(item.date_created)
                      
                    return (
                        <div
                            key={idx}
                            className='flex flex-col px-2.5 my-4'>

                            <div className='flex'>
                            <div className="h-[50px] w-[50px] ">
                            <img
                            src={user && user?.profileImage}
                            alt="User Avatar"
                            className={`w-full h-full rounded-full`}
                            />
                            </div>

                            <div className='mx-3 w-[85%]'>
                                <p className='text-[10px]'>{dateObject}</p>
                                <p className='my-1'>{item.text}</p>

                            </div>

                            </div>
                            <div className='flex justify-end'>
                                    <div className='flex space-x-4'>
                                        <p>
                                            {item.upvote_count}
                                            <GoThumbsup className='mx-1 inline' />
                                        </p>
                                        <p>
                                            {item.downvote_count}
                                            <GoThumbsdown className='mx-1 inline' />
                                        </p>
                                    </div>

                                    <div className='justify-end ml-2'>
                                    <Link

                                    to={`/dashboard/${item?.post}`}
                                    className='bg-updatedPurple px-3 py-1
                                        hover:bg-[#767cb9]'>
                                        Go to comment
                                    </Link>
                                    </div>

                                </div>
                        </div>
                    )
                })
                :
                <p className='text-xl text-center'>No Activity</p>
                
                
                )}



            </div>
        </div>
    )
}

export default UserActivity
