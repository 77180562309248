import React, { useEffect, useRef, useState} from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import cytoscape from 'cytoscape';
import avsdf from 'cytoscape-avsdf';
import { layoutOptions, stylesheet } from './layoutOptions';
import { showPsychometricDataToUser } from './reusablePsychometricFunctions';

cytoscape.use( avsdf );

const UserPsychometric = ({data, bgColor}) => {

  const cyRef = useRef(null);
  const [feedBack, setFeedBack] = useState("")


  const userInteraction = (cy) => {
    let previousTapTarget = null;

    cy.on("tap", (event) => {
      const result = showPsychometricDataToUser(event, cy, previousTapTarget, setFeedBack)
      previousTapTarget = result
    });
  }

  useEffect(() => {

    if (cyRef.current) {
      const cy = cyRef.current;
      // Apply layout after a short delay to ensure all elements are loaded
      setTimeout(() => {
        cy.layout(layoutOptions).run();
      }, 100);
      userInteraction(cy);
    }

  // eslint-disable-next-line 
  }, [data]);



  return (
    <div className='w-[500px] relative '>
    {feedBack.length > 0 && 
    <div className='absolute z-[4] top-4 right-2 p-3 rounded-md bg-nightModeDark text-white'>
      <p>{feedBack}</p>
    </div>}
    <CytoscapeComponent
        elements={CytoscapeComponent.normalizeElements(data)}
        style={{
          width:"450px",
          height:"350px",
          backgroundColor: bgColor ? bgColor : "#D20062",
          color: "white"
        }}
        layout={layoutOptions}
        stylesheet={stylesheet}
        cy={(cy) => { cyRef.current = cy; }}
        minZoom={1}
        maxZoom={1.2}
        pan={{ x: 70, y: 50 }}
        autoungrabify={false}
      />
  

   </div>
  );
};

export default UserPsychometric;