export const isTranscriptDeleted =  (transcripts) => {
    if(transcripts && transcripts?.detail === "No Transcript matches the given query."){
        return []
    }else{
        return transcripts?.filter(Boolean)
    }
    
}

export const showErrorMessages = (message, setStateBool, setStateStr) => {
    setStateBool(true)
    setStateStr(message)
}