import React from 'react'

const Opinion = () => {
  return (
    <div className='bg-[#101010] rounded-lg w-full lg:w-[50%]'>
     <div className='p-2 md:p-5 m-5'>
      <h1 className='font-nunito italic text-base md:text-2xl'>What do you think?</h1>
     </div>

     <div className=' p-2 m-4 md:p-5 md:m-8 w-full'>
      <h1 className='font-nunito font-semibold text-base md:text-2xl'>Should there be a fixed tax on soda?</h1>
      
      <div className='mt-4'>
       <p className='italic font-nunito text-sm md:text-xl'>Total Voters: 234,982 users</p>
      </div>

      <div className='mt-4  flex flex-row space-x-2 md:space-x-10 items-center w-full'>
     
      <div >
      <button className='bg-[#7AA65F] py-4 px-8 md:px-20 md:py-8 rounded-xl text-center font-bold font-nunito text-base md:text-3xl'>Yes</button>
      <p className='italic font-nunito text-sm md:text-base mt-1 text-center'>201,923 votes</p>
      <p className='italic font-nunito text-sm md:text-base mt-1 text-center'>82.03%</p>
      </div>

      <div className=''>
      <button className='bg-[#F66262] py-4 px-8 md:py-8 md:px-20 rounded-xl text-center font-bold font-nunito text-base md:text-3xl'>No</button>
      <p className='italic font-nunito text-sm md:text-base mt-1 text-center'>32,283 votes</p>
      <p className='italic font-nunito text-sm md:text-base mt-1 text-center'>17.97%</p>
      </div>
      
      </div>
      <div className=' mt-2 mr-4 md:m-4'>
        <p className='font-nunito italic text-sm md:text-xl '>
        Currently, the votes are in favor of a fixed tax on soda. There is a remaining time of around 50 minutes for this debate. To change your vote, click on the opposite button.
        </p>
      </div>
     </div>

    </div>
  )
}

export default Opinion