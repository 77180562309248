import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ChatPrompt from "./ChatPrompt";
import ChatSurvey from './chatSurvey';
 // eslint-disable-next-line
import { LiaToggleOffSolid, LiaToggleOnSolid } from "react-icons/lia";
import { IoMenu } from "react-icons/io5";
import { useSelector, useDispatch } from 'react-redux';
import { allBotsChats, userInfo } from "../../redux/Selectors/selectors";
import { changeBackgoundMode, selectbackground } from "../../redux/Slices/toggleBackgroundSlice";
import { getUserChatFromAPIAsync, postUserNeed } from "../../redux/Thunks/chatBotThunk";
import { addOne, selectRenderedState, surveyActivity, toggleChatSideBar } from '../../redux/Slices/sharedUseEffectSlice';
import { generateSurvey } from '../../redux/Thunks/chatBotThunk';
import Loading from '../common/loading';
import Alert from "../common/Alert";
import { isTranscriptDeleted, showErrorMessages } from '../common/checks_Func';

const ChatBoxByTranscript = () => {
    const divRef = useRef(null);
    const isSurveyTime = useSelector(state => state.shared_useEffect.isFive);
    const surveyCarriedOut = useSelector(state => surveyActivity(state));

    let userID;
    const userDetails = useSelector(userInfo);
    if (userDetails) {
        userID = userDetails.id;
    }

    const { transcriptID } = useParams(); // Access the parameter value
    const dispatch = useDispatch();
    const currentMode = useSelector(state => selectbackground(state)); // Light and Dark Mode
    const isRendered = useSelector(state => selectRenderedState(state));

    const [userPrompt, setUserPrompt] = useState("");
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Oops! Something went wrong.");
    // Fetch each user transcripts and unique chats
    const userChatsUnCleaned = useSelector(state => allBotsChats(state));
    
    const userChats = isTranscriptDeleted(userChatsUnCleaned)
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    async function timeoutAlert() {
        if (showError || showSuccess) {
            // Set a timeout to hide the error alert after 5 seconds
            const timeout = setTimeout(() => {
                setShowError(false);
                setShowSuccess(false);
            }, 4000);

            // Clear the timeout if the component unmounts or if showError changes
            return () => clearTimeout(timeout);
        }
    }

    function fetchData() {
        if (isRendered && userDetails) {
            dispatch(
                getUserChatFromAPIAsync({
                    transcript_id: transcriptID
                })
            );

            const timer = setTimeout(() => {
                setIsPopupOpen(true);
            }, 5000); // 5 seconds
            return () => clearTimeout(timer);
        }
    }

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setShowSuccess(true);
    };

    const handleError = () => {
        setShowError(true);
    };

    // Background change
     // eslint-disable-next-line
    const handleBackgroundChange = () => {
        dispatch(changeBackgoundMode());
    };

    const toggleNavbar = () => {
        dispatch(toggleChatSideBar());
    };

    const handleClick = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (userDetails) {
            dispatch(
                addOne({ // Increase isSurveyTime by 1.
                    isFive: isSurveyTime + 1
                })
            );

            try {
                // DISPATCH THE USER PROMPT
                const sendChats = await dispatch(
                    postUserNeed({
                        prompt: userPrompt,
                        transcriptID: transcriptID,
                        user_id: userID
                    })
                ).unwrap();

                if (sendChats) {
                    setLoading(false);
                    setUserPrompt("");
                    // IF THE USER CHATS GOT A RESPONSE, CHECK IF SURVEY HAS BEEN CARRIED OUT
                    // AND IF THE SURVEY NUMBER IS GREATER THAN 1
                    // IF SO, GENERATE A SURVEY AFTER 2 SECONDS
                    setTimeout(() => {
                        if (isSurveyTime >= 1 && !surveyCarriedOut) {
                            dispatch(
                                generateSurvey({
                                    user_id: userID,
                                    transcript_id: transcriptID
                                })
                            ).unwrap();
                        }
                    }, 1500);
                }
            } catch (error) {
                if (error) {
                    setLoading(false);
                    setShowError(true);
                }
            }
        } else {
            setLoading(false);
            setErrorMsg("Ooops, you are not logged in.");
            setShowError(true);
        }
    };


    useEffect(() => {
        setShowError(false)
        if(userChats.length === 0){
            showErrorMessages(`The conversation with ID: ${transcriptID} does not exist!`,
                 setShowError, setErrorMsg)
        }else{
            setShowError(false)
            setErrorMsg("")
        }

        if (divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [userChats, transcriptID]);


    useEffect(() => {
        timeoutAlert();
        fetchData();
        // eslint-disable-next-line
    }, [userDetails, showError, showSuccess, isRendered]);


    return (
        <div className={`w-full lg:w-4/5 relative h-screen
        ${!currentMode ? `bg-white text-black` : `bg-nightModeDark text-white`} `}>


            <div className='relative overflow-y-scroll no-scrollbar
            h-screen px-3 sm:px-5 pb-[70px]'>


            <div className={`sticky top-0 z-10 w-full flex justify-between
             ${!currentMode ? `bg-white text-black`
             : `bg-nightModeDark text-white`}`} >

            <button
                className="lg:hidden m-2.5 text-2xl"
                onClick={toggleNavbar}>
                <IoMenu
                className={`${currentMode ?
                "text-chatTipBackground" : "text-nightModeDark"}`} />
            </button>

            

                    {/* <div className='flex justify-end sm:w-full'>
                    <button
                className="m-2.5 text-2xl lg:text-4xl"
                onClick={handleBackgroundChange}>
                {currentMode ? <LiaToggleOnSolid /> : <LiaToggleOffSolid />}
            </button>
                    </div> */}

            </div>

                {userChats && userChats?.map(({ chat_id, user_response, ai_response }) => {
                    return <div key={chat_id} className='my-6 w-full'>
                        <div className={`flex justify-end items-end mx-2 relative `}>
                            <div className='w-[85%] md:w-3/5'>
                                {userDetails &&
                                    <p className='flex justify-end font-bold text-sm
                                sm:text-[16px] my-1 sm:my-2.5 sm:mr-7'>
                                        {userDetails.name}</p>}
                                <p
                                    className={`my-2 sm:m-4 rounded-2xl p-2.5
                            bg-chatTipBackground text-black border-transparent `}
                                >
                                    {user_response}
                                </p>
                            </div>
                        </div>

                        <div className={`flex justify-start items-start mt-3 mx-2 `}>
                            <div className='w-[90%] md:w-3/5'>
                                <p className='my-1 sm:m-2.5 font-bold
                        text-sm sm:text-[16px]'>OpenPolitica Bot</p>

                                <div className={`bg-chatTipBackground text-black sm:my-2.5 rounded-2xl`}>
                                    <div className={`rounded-t-lg p-4 border-transparent`}>
                                        {ai_response}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                })}

                <div ref={divRef} className="pt-[50px]" />

            </div>

            {loading && <Loading width="w-full lg:w-4/5" />}
            {showError && <Alert
                isVisible={showError}
                message={errorMsg}
                type="error"
            />}
            {showSuccess && <Alert
                isVisible={showSuccess}
                message="Thank you for your feedback."
                type="success"
            />}

            {userChats.length > 0 && <ChatPrompt
                currentMode={currentMode}
                bgOnDark="bg-nightModeDark"
                placeholder='Hi, Let’s talk policies today!'
                position="sticky"
                submitPrompt={handleClick}
                value={userPrompt}
                handleChange={e => setUserPrompt(e.target.value)} />
            }

            <ChatSurvey
                onError={handleError}
                isOpen={isPopupOpen}
                transcript_id={transcriptID}
                onClose={handleClosePopup}
            />

        </div>
    );
}

export default ChatBoxByTranscript;
