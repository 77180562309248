import React from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'

function DashboardMobileScreenFilter({handleClick, hideMap, whatToDisplay }) {
  return (
    <div className='flex md:hidden justify-between flex-wrap'>
    <div className='w-[60%] flex '>
        <h1 className='py-2 pl-2.5 text-white font-nunito text-lg not-italic 
        font-bold leading-normal'>
            {whatToDisplay === "policy-cards" ? 
            "Trending Policies"
             :
             "Debate Room"}
        </h1>
    </div>
    <div
        className={`w-[38%] ${!hideMap && "bg-[#284B63]"} flex justify-end 
        py-2.5 px-2 rounded-tr-xl`}
        onClick={handleClick}
    >
        <h1 className='text-white font-nunito not-italic font-bold leading-normal'>
            Filter By
        </h1>
        {!hideMap ?
            <AiFillCaretDown className="ml-1 mt-1 text-white" />
            :
            <AiFillCaretUp className="ml-1 mt-1 text-white" />
        }
    </div>
</div>
  )
}

export default DashboardMobileScreenFilter
