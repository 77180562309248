import React from 'react'
import Footer from '../../layout/Footer'
import DebateRoom from './DebateRoom'
import CommentRoom from './CommentRoom'
import Summary from './summaries/summary'
import DebateUpdate from './DebateUpdates/DebateUpdate'
import Opinion from './DebateUpdates/Opinion'


const Discussion = () => {
  return (
    <div className='bg-nightModeDark text-white min-h-max'>
    <div className='flex flex-col lg:flex-row gap-4  m-4'>
    <DebateRoom/>
    <Summary/>
    </div>

    <div className='flex flex-col lg:flex-row gap-4  m-4'>
      <DebateUpdate/>
      <Opinion/>
    </div>

    <CommentRoom/>

      <Footer/>
    </div>
  )
}

export default Discussion