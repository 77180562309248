import React from 'react'
import PolicyWeb from './UserPol/PolicyWeb'
import PolicyGenerated from './UserPol/PolicyGenerated'

function UserPolicyCards({ isMobileClicked, onClick }) {

  const handleClick = (index) => {
    onClick(index)
}
  return (
    <div className='relative mb-5 lg:my-0 lg:py-6 bg-nightModeDark
     lg:h-[725px] rounded-xl '>

      <div className='sticky lg:hidden font-semibold flex justify-between'>
        <button
         onClick={() => {
                handleClick(0)
            }}
          className={`font-semibold w-2/4 py-3
             ${isMobileClicked && isMobileClicked[0] ? "bg-nightModeDark" : "bg-greenOnDarkMode"}
             `}>
          Recent Activity</button>

        <button
          onClick={() => {
                handleClick(1)
            }}
          className={`font-semibold w-2/4 py-3
             ${isMobileClicked && isMobileClicked[1] ? "bg-nightModeDark" : "bg-greenOnDarkMode"}
             `}>
          Policy Web</button>

      </div>


      <PolicyWeb />
      <PolicyGenerated />
    </div>
  )
}

export default UserPolicyCards
