import React from 'react'

const SortBy = () => {
  return (
    <div className='sm:flex flex-col w-1/4 hidden md:block'>
      <div className='p-5 flex justify-between items-center'>
        <h1 className='font-nunito font-bold text-2xl'>
        Sort By:
        </h1>
        <div>
        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1319 14.6969C11.3348 15.8219 9.66523 15.8219 8.86813 14.6969L1.04509 3.65628C0.106607 2.3318 1.05369 0.5 2.67697 0.5L18.323 0.5C19.9463 0.5 20.8934 2.3318 19.9549 3.65629L12.1319 14.6969Z" fill="white"/>
        </svg>
        </div>
      </div>
      <div className='p-5 flex justify-between items-center'>
        <p className='font-medium text-xl'>Top Trending</p>
        <input type="radio" className="appearance-none  checked:bg-[#3C6E71] " />
      </div>
      <div className='p-5 flex justify-between items-center'>
        <p className='font-medium text-xl'>Most Replies</p>
        <input type="radio" className="appearance-none  checked:bg-[#3C6E71] " />
      </div>
      <div className='p-5 flex justify-between items-center'>
        <p className='font-medium text-xl'>Most Reactions</p>
        <input type="radio" className="appearance-none  checked:bg-[#3C6E71] " />
      </div>
      <div className='p-5 flex justify-between items-center'>
        <p className='font-medium text-xl'>Most Recent</p>
        <input type="radio" className="appearance-none  checked:bg-[#3C6E71] " />
      </div>
    </div>
  )
}

export default SortBy