import React from 'react'
import { useSelector } from 'react-redux'
import { userInfo } from '../../redux/Selectors/selectors'
import { GoThumbsdown, GoThumbsup } from 'react-icons/go'
import { Link } from 'react-router-dom'

function DiscussionActivity({topic, author, username, date, text,
                            upvote_count, downvote_count }) {

  const user = useSelector(userInfo)

  return (
        // Had to copy the EachDebateActivity so as to change the redirecting of the open Dicussion btn.
    <div className='space-y-2 my-8'>
    <p className=''>{topic}</p>
    <p className='italic text-sm font-light'>Posted by: {author}</p>
    <div className='flex'>
            <div className="h-[40px] w-[40px] sm:h-[60px] sm:w-[60px]">
                    <img
                    src={user && user?.profileImage}
                    alt="User Avatar"
                    className={`w-full h-full rounded-full`}
                    />
                </div>
            <div className='ml-2'>
                <p className='text-[14px]'>{username}</p>
                <p className='text-[10px]'>{date}</p>

                <p className='my-2.5'>{text}</p>

                <div className='flex text-xs'>
                <div className='flex space-x-4'>
                                <p>
                                    {upvote_count}
                                    <GoThumbsup className='mx-1 inline' />
                                </p>
                                <p>
                                    {downvote_count}
                                    <GoThumbsdown className='mx-1 inline' />
                                </p>
                            </div>
                </div>
            </div>
    </div>

    <Link to="">
    <div className='flex justify-end my-3 md:pr-5'>
        <button className='px-6 sm:px-10 py-2 bg-[#474B7D] rounded-md 
        text-sm sm:text-[16px]'>Open Discussion</button>
    </div>
    </Link>
</div>
  )
}

export default DiscussionActivity
