import React, { useState } from 'react'
import AiSummary from './AiSummary'
import Psychometrics from './Psychometrics'
import VideoFiles from './VideoFiles'


const Summary = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleClickTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const getButtonStyles = (tabIndex) => {
    return activeTab === tabIndex
      ? 'bg-black-800 text-white' // Active button styles ( black background and white text)
      : 'bg-[#202020] text-white'; // Inactive button styles (gray background and white text)
  };

  return (
    <div className='h[80vh] lg:h-[60vh] overflow-y-scroll scroll-bar-in-dark w-full lg:w-1/3 rounded-lg bg-black'>
      <div className='flex justify-between items-center gap-1 flex-row '>
        <button
          className={`${getButtonStyles(1)} px-4 py-2 w-1/3`} 
          onClick={() => handleClickTab(1)}
        >
          <span className='font-nunito text-xs md:text-xl font-bold'>Ai Summary</span>
        </button>
        <button
          className={`${getButtonStyles(2)} px-4 py-2 w-1/3`} 
          onClick={() => handleClickTab(2)}
        >
          <span className='font-nunito text-xs md:text-xl font-bold'>Video Files</span>
        </button>
        <button
          className={`${getButtonStyles(3)} px-4 py-5 md:py-2  w-1/3`} 
          onClick={() => handleClickTab(3)}
        >
          <span className='font-nunito text-[10px] md:text-xl font-bold'>Psychometrics</span>
        </button>
      </div>

      <div className='p-[20px] mt-[10px] bg-black'>
        {activeTab === 1 && <div><AiSummary/></div>}
        {activeTab === 2 && <div><VideoFiles/></div>}
        {activeTab === 3 && <div><Psychometrics/></div>}
      </div>
    </div>
  );
};

export default Summary;

