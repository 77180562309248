export const headers = (token) => {
  return {
    "Content-Type": "application/json",
    "Authorization": token ? `Bearer ${token}` : undefined,
  };
};



export const headerConfiguration = (getState,header) => {
  const currentState = getState()
  const storedToken = currentState?.persistedReducer?.auth?.token
  const accessToken = storedToken?.accessToken


  let headerConfig = header(accessToken)
  return headerConfig
}