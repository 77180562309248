import React from 'react'
import { Link } from 'react-router-dom'

const VideoFiles = () => {
  return (
    <div className=' flex  flex-row gap-3 w-full flex-wrap'>

    <div className='flex  flex-col m-2'>
    <svg width="220" height="131" viewBox="0 0 220 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="220" height="131" rx="5" fill="#202020"/>
    <circle cx="110" cy="66" r="20" fill="#D9D9D9"/>
    <path d="M105.794 58.5L119.294 66.2942L105.794 74.0885L105.794 58.5Z" fill="#101010"/>
    </svg>

    <h2 className='font-bold text-bas text-baseesm: sm:text-xl font-nunito mt-1'>
     Summmary of Video
    </h2>

    <h2 className='font-bold  text-base sm:text-xl font-nunito '>
     Video summary of
    </h2>

    <p className='font-nunito italic text-sm'>
      posted by: User
    </p>
    <Link to='' className=' bg-[#474B7D] mt-[2px] text-center font-bold sm:text-xl rounded-lg'>
     Jump to comment
    </Link>
    </div>
 {/* second video evidence */}
    <div className='flex  flex-col m-2'>
    <svg width="220" height="131" viewBox="0 0 220 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="220" height="131" rx="5" fill="#202020"/>
    <circle cx="110" cy="66" r="20" fill="#D9D9D9"/>
    <path d="M105.794 58.5L119.294 66.2942L105.794 74.0885L105.794 58.5Z" fill="#101010"/>
    </svg>

    <h2 className='font-bold text-base sm:text-xl font-nunito mt-1'>
     Summmary of Video
    </h2>

    <h2 className='font-bold text-base sm:text-xl font-nunito '>
     Video summary of
    </h2>

    <p className='font-nunito italic text-sm'>
      posted by: User
    </p>
    <Link to='' className=' bg-[#474B7D] mt-[2px] text-center font-bold sm:text-xl rounded-lg'>
     Jump to comment
    </Link>
    </div>

    {/* third video evidence */}
    <div className='flex  flex-col m-2'>
    <svg width="220" height="131" viewBox="0 0 220 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="220" height="131" rx="5" fill="#202020"/>
    <circle cx="110" cy="66" r="20" fill="#D9D9D9"/>
    <path d="M105.794 58.5L119.294 66.2942L105.794 74.0885L105.794 58.5Z" fill="#101010"/>
    </svg>

    <h2 className='font-bold text-base sm:text-xl font-nunito mt-1'>
     Summmary of Video
    </h2>

    <h2 className='font-bold text-base sm:text-xl font-nunito '>
     Video summary of
    </h2>

    <p className='font-nunito italic text-sm'>
      posted by: User
    </p>
    <Link to='' className=' bg-[#474B7D] mt-[2px] text-center font-bold sm:text-xl rounded-lg'>
     Jump to comment
    </Link>
    </div>

    {/* fourth video evidence */}
    <div className='flex  flex-col m-2'>
    <svg width="220" height="131" viewBox="0 0 220 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="220" height="131" rx="5" fill="#202020"/>
    <circle cx="110" cy="66" r="20" fill="#D9D9D9"/>
    <path d="M105.794 58.5L119.294 66.2942L105.794 74.0885L105.794 58.5Z" fill="#101010"/>
    </svg>

    <h2 className='font-bold text-base sm:text-xl font-nunito mt-1'>
     Summmary of Video
    </h2>

    <h2 className='font-bold text-base sm:text-xl font-nunito '>
     Video summary of
    </h2>

    <p className='font-nunito italic text-sm'>
      posted by: User
    </p>
    <Link to='' className=' bg-[#474B7D] mt-[2px] text-center font-bold sm:text-xl rounded-lg'>
     Jump to comment
    </Link>
    </div>

    {/* fifth video evidence */}
    <div className='flex  flex-col m-2'>
    <svg width="220" height="131" viewBox="0 0 220 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="220" height="131" rx="5" fill="#202020"/>
    <circle cx="110" cy="66" r="20" fill="#D9D9D9"/>
    <path d="M105.794 58.5L119.294 66.2942L105.794 74.0885L105.794 58.5Z" fill="#101010"/>
    </svg>

    <h2 className='font-bold text-base sm:text-xl font-nunito mt-1'>
     Summmary of Video
    </h2>

    <h2 className='font-bold text-base sm:text-xl font-nunito '>
     Video summary of
    </h2>

    <p className='font-nunito italic text-sm'>
      posted by: User
    </p>
    <Link to='' className=' bg-[#474B7D] mt-[2px] text-center font-bold sm:text-xl rounded-lg'>
     Jump to comment
    </Link>
    </div>
    </div>
  )
}

export default VideoFiles