import { createSlice } from "@reduxjs/toolkit";

const user =[]


const randomUserProfileSlice = createSlice({
    name:"randomUser",
    initialState: user,
    reducers:{}
})

// export const {

// } = userSlice.actions

export default randomUserProfileSlice.reducer
