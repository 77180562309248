import React from 'react'
import StatsPsychometrics from '../../Dashboard/Statistics/StatsPsychometrics'

const Psychometrics = () => {
  return (
    <div>
    <h2 className='font-semibold font-nunito text-base md:text-2xl mb-3'>
      Where are most users based?
    </h2>

{/* this the bar representation for users */}
    <div className='space-y-4'>
    <div className='bg-[#DA1E1E] w-3/4 rounded-lg h-5'/>
    <div className='bg-[#7CBE65] w-1/2 rounded-lg h-5'/>
    <div className='bg-[#7579CD] w-1/4 rounded-lg h-5'/>
    <div className='bg-[#EC8D9E] w-1/12 rounded-lg h-5'/>
    </div>

    <div>
      <h2 className='font-semibold font-nunito text-base md:text-2xl mt-3 mb-2'>Most Searched Topics</h2>
      <ul className='font-semibold'>

        <li className='text-sm md:text-xl italic'>Labor Laws</li>
        <li className='text-sm md:text-xl italic'>Taxes</li>
        <li className='text-sm md:text-xl italic'>Oil Prices</li>

      </ul>
    </div>

    <div>
      <h2 className='font-semibold font-nunito text-base md:text-2xl mt-3 mb-2'>Psychometrics</h2>
        <div>
         <StatsPsychometrics/>
       </div> 
    </div>
    </div>

  )
}

export default Psychometrics