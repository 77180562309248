import React from 'react'
import DebateChat from '../../DebateRoom/DebateChat'


const ChatRoom = () => {
  return (
    <div className='p-1 md:p-10 relative h-[80vh] md:max-h-[50vh] mb-10 overflow-y-scroll scroll-bar-in-dark'>

          <DebateChat
            name='James McFly'
            stance='Hello you have requested a policy card for Kansas! what would you like to know?'
            time='05:03:27PM CST'
            containerClass='w-1/2'
          />

          <DebateChat
            name='Martin Kellogg'
            stance='I would like to know about the policy that just passed 2 days ago about gun laws.'
            time='05:07:31PM CST'
            containerClass=' w-1/2 float-right'
          />
      <div className='w-full flex justify-center text-center my-4'>
        <p className='font-nunito text-sm sm:text-xl italic'>PolicyWeb: Are there any more points?</p>
      </div>

          <DebateChat
            name='James McFly'
            stance='Hello you have requested a policy card for Kansas! what would you like to know?'
            time='05:03:27PM CST'
            containerClass='w-1/2'
          />

          <DebateChat
            name=''
            stance='Martin Kellogg typing...'
            time=''
            containerClass=' float-right'
          />

    </div>
  );
};

export default ChatRoom;


