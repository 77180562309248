import React from 'react'
import { readableDate } from '../common/set_date'
import { debates } from '../../mockups/Dashboard/debates'
import { Link } from 'react-router-dom'
import Debaters from './Debaters'
import img1 from "../../assets/img/avatar.png"


function DashTrendingDebates() {

  return (
    <div className={`bg-nightModeDark_600 relative rounded-2xl text-white mb-10 py-5 px-2 sm:px-5`}>

        <p className='absolute -top-3 -right-5 rotate-45 text-5xl'>🔥</p>
        <p className='text-xl sm:text-2xl font-bold mb-5'>Debate Room (Trending)</p>

    <div className='bg-nightModeDark flex flex-col sm:justify-center py-5 px-2.5 sm:px-4'>

                <div className='flex justify-around flex-col sm:px-4 mt-5'>
                <h3 className='text-2xl font-bold'>Should there be a fixed tax on soda?</h3>
                  <p className='italic font-light my-1'><i>Created by:</i> PolicyWeb</p>
                  <p className='mt-4'><i>Started:</i> 12:00:00PM CST
                  <br />
                  Remaining time: 49:32:23</p>
                </div>

                <div className='flex justify-between sm:justify-center my-5'>
                <Debaters
                name="James McFly" 
                profileID={14}
                userImg={img1}
                stance="Yes, the ..."
                 />

                <div className='my-12 sm:my-2 mr-5 sm:mr-10'>
                <h3 className='text-[18px] sm:text-2xl font-bold'>Vs</h3>
                </div>
                
                <Debaters
                name="Martin Kellogg"
                profileID={15}
                userImg={img1}
                stance="No, in th..."
                 />
                </div>



          <Link to="/discussion">
          <div className='flex justify-center md:pr-5'>
          <button className='w-full sm:w-3/4 px-4 sm:px-10 py-2 bg-[#474B7D] hover:bg-[#444770] rounded-md 
          text-sm sm:text-[16px]'>Enter Debate Room</button>
          </div>
          </Link>
            </div>


        
      
    </div>
  )
}

export default DashTrendingDebates
