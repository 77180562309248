import React from 'react'
import EachDebateActivity from './EachDebateActivity'
import { readableDate } from '../common/set_date'
import { debates } from '../../mockups/Dashboard/debates'




function DashUserDebateTopics() {

    let height = "h-[100vh]"
    if(debates.length < 4){
        height = "h-[70vh] sm:h-[55vh]"
    }

  return (
    <div className={`${height} relative bg-nightModeDark_600 rounded-lg text-white overflow-y-scroll
     scroll-bar-in-dark`}>
 <p className='sticky top-0 p-4 bg-nightModeDark_600 w-full 
 text-xl sm:text-2xl font-bold mb-5'>Discussions</p>
        
        {debates.map(({eid,post_title, author, username, text, date_created,
                         upvote_count, downvote_count}, idx) => {
            
            const dateObject = readableDate(date_created)

            return (<div className='px-4 sm:px-5' key={idx}>
                <EachDebateActivity
                topic={post_title} 
                author={author}
                username={username} 
                date={dateObject}
                text={text}
                upvote_count={upvote_count}
                downvote_count={downvote_count}
         />
            </div>)
        })}

        
        
      
    </div>
  )
}

export default DashUserDebateTopics
